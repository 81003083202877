.App {
  text-align: center;
}
body {
  padding: 0%;
  margin: 0%;
  font-family: Arial, Helvetica, sans-serif;
}

nav {
  margin: 0;
  width: 100%;
  /*height: 80px;*/
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
}

a {
  color: white;
  text-decoration: none;
  margin: 10px;
  font-size: 25px;
}

.createPostPage {
  width: 100%;
  height: calc(100vh - 80px);
  display: grid;
  place-items: center;
}

.cpContainer {
  width: auto;
  min-width: 30%;
  max-width: 90%;
  height: auto;
  padding: 20px;
  background-color: black;
  border-radius: 12px;
  color: white;
  display: flex;
  flex-direction: column;
}

.cpContainer h1 {
  text-align: center;
}

.cpContainer label {
  font-size: 25px;
}
.cpContainer .inputGp {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.inputGp input,
.inputGp textarea {
  font-size: 18px;
  border: none;
  border-radius: 2px;
  padding: 5px;
}

.inputGp input {
  height: 40px;
}
.inputGp textarea {
  height: 150px;
}

.cpContainer button {
  margin-top: 20px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}
input,
textarea {
  margin-top: 5px;
}

.loginPage {
  width: 100vw;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginPage p {
  font-size: 30px;
}

/* GOOGLE BUTTOn */
.login-with-google-btn {
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 5px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  color: #757575;
  font-size: 25px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}
.login-with-google-btn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}
.login-with-google-btn:active {
  background-color: #eeeeee;
}
.login-with-google-btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
  0 0 0 3px #c8dafc;
}
.login-with-google-btn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.homePage {
  width: 100%;
  min-height: calc(100vh - 80px);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.homePage .post {
  width: auto;
  min-width: 70%;
  height: auto;
  max-height: 600px;
  background-color: rgb(250, 250, 250);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 20px;
  padding: 20px;
  border-radius: 15px;
}

.post .postHeader {
  display: flex;
  justify-content: center;
  width: 100%;
}

.postHeader .title {
  flex: 50%;
}
.postHeader .deletePost {
  flex: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.deletePost button {
  border: none;

  background: none;
  font-size: 30px;
  cursor: pointer;
}

.post .postTextContainer {
  word-wrap: break-word;
  height: auto;
  max-height: 400px;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.box {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px 30px;
}

.item1 {
  background-color: indianred;
}

.parent {
  display: flex;
  height: 30px; /* Or whatever */
}

.child {
  width: 100px;  /* Or whatever */
  margin: auto;  /* Magic! */
}



.flex-container {
  /* We first create a flex layout context */
  display: flex;

  /* Then we define the flow direction
     and if we allow the items to wrap
   * Remember this is the same as:
   * flex-direction: row;
   * flex-wrap: wrap;
   */
  flex-flow: row wrap;

  /* Then we define how is distributed the remaining space */
  justify-content: left;

  padding: 0px;
  margin: 5px;
  list-style: none;

  background-color: lightgrey;
}

.flex-item {
  background: tomato;
  padding: 2px;
  width: 150px;
  height: 30px;
  margin-top: 10px;
  line-height: 30px;
  color: white;
  font-weight: normal;
  font-size: 1em;
  text-align: center;
}

.flex-item3 {
  padding: 2px;
  max-width: 600px;
  height: 30px;
  margin-top: 10px;
  padding-right: 5px;
  line-height: 30px;
  font-weight: normal;
  font-size: 1em;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  /*background-color: #3e8e41;*/
}

.flex-item33 {
  padding: 2px;
  max-width: 375px;
  height: 130px;
  margin-top: 10px;
  padding-right: 5px;
  line-height: 30px;
  font-weight: normal;
  font-size: 1em;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  /*background-color: #3e8e41;*/
}

.flex-item-short {
  background: tomato;
  padding: 2px;
  width: 100px;
  height: 30px;
  margin-top: 10px;
  line-height: 30px;
  color: white;
  font-weight: normal;
  font-size: 1em;
  text-align: center;
}

.flex-item2{
  background: tomato;
  padding: 1px;
  width: 180px;
  height: 32px;
  margin-top: 10px;
  line-height: 30px;
  color: white;
  font-weight: normal;
  font-size: 1em;
  text-align: center;
}

.flex-item4{
  padding: 1px;
  width: 160px;
  height: 32px;
  margin-top: 10px;
  line-height: 30px;
  font-weight: normal;
  font-size: 1em;
  text-align: left;
}

.flex-item30{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  background-color: #3e8e41;
  padding-left: 0px;
  padding-right: 5px;
  max-width: 300px;
  height: 32px;
  margin-top: 10px;
  line-height: 32px;
  font-weight: normal;
  font-size: 1em;
  text-align: left;
  alignment: left;
}

.flex-item36 {
  display: grid;
  /*background-color: orange;*/
  alignment: left;
  padding-top: 0px;
  height: 50px;
  /*vertical-align: top;*/
}

.flex-item35 {
  padding-left: 10px;
  padding-top: 10px;
  max-width: 400px;
  height: 32px;
  margin-top: 10px;
  line-height: 30px;
  font-weight: normal;
  font-size: 1em;
  text-align: left;
  alignment: left;
  /*background-color: gold;*/
  display: inline-flex;
  flex-flow: initial;
  justify-content: flex-start;
}

.flex-item40{
  padding-left: 10px;
  padding-top: 10px;
  width: 150px;
  height: 32px;
  margin-top: 10px;
  line-height: 30px;
  font-weight: normal;
  font-size: 1em;
  text-align: left;
  alignment: left;
  /*background-color: gold;*/
}

.flex-item41{
  padding-left: 10px;
  padding-top: 10px;
  width: 400px;
  height: 200px;
  margin-top: 10px;
  line-height: 30px;
  font-weight: normal;
  font-size: 1em;
  text-align: left;
  alignment: left;
  /*background-color: gold;*/
}

.flex-item44{
  padding-left: 10px;
  padding-top: 10px;
  width: 360px;
  height: 32px;
  margin-top: 10px;
  line-height: 30px;
  font-weight: normal;
  font-size: 1em;
  text-align: left;
  alignment: left;
  /*background-color: gold;*/
}

.flex-item411{
  padding-left: 20px;
  padding-top: 20px;
  width: 100%;
  height: 30px;
  margin-top: 10px;
  line-height: 30px;
  font-weight: normal;
  font-size: 1em;
  text-align: left;
  alignment: left;
  /*background-color: gold;*/
}

.flex-item42{
  padding-left: 10px;
  padding-top: 10px;
  width: 1400px;
  height: 32px;
  margin-top: 10px;
  line-height: 30px;
  font-weight: normal;
  font-size: 1em;
  text-align: left;
  alignment: left;
  /*background-color: gold;*/
}

.flex-item50{
  padding-left: 20px;
  padding-top: 10px;
  width: 400px;
  height: 32px;
  margin-top: 5px;
  font-weight: normal;
  font-size: 1em;
  text-align: left;
  /*background-color: #3e8e41;*/
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  alignment: right;
}

.flex-item60{
  padding-left: 10px;
  width: 500px;
  margin-top: 5px;
  font-weight: normal;
  font-size: 1em;
  text-align: left;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  alignment: left;
  /*background-color: orange;*/
}

.flex-item-short4 {
  padding: 2px;
  width: 100px;
  height: 30px;
  margin-top: 10px;
  line-height: 30px;
  font-weight: normal;
  font-size: 1em;
  text-align: left;
}

.flex-item-short5 {
  padding-top: 3px;
  padding-bottom: 3px;
  width: 100px;
  height: 30px;
  margin-top: 10px;
  line-height: 30px;
  font-weight: normal;
  font-size: 1em;
  text-align: left;
}

.row1 {
  background-color: burlywood;
}

.flex-item-date {
  padding-right: 10px;
  width: 120px;
  height: 10px;
  margin-top: 10px;
  line-height: 10px;
  font-weight: normal;
  font-size: 15px;
  text-align: right;
  color: darkgrey;
}

.flex-item-time {
  padding-right: 10px;
  width: 70px;
  height: 10px;
  margin-top: 10px;
  line-height: 10px;
  font-weight: normal;
  font-size: 15px;
  text-align: right;
  color: darkgrey;
}

.flex-item-day {
  padding-right: 10px;
  width: 70px;
  height: 12px;
  margin-top: 0px;
  line-height: 12px;
  font-weight: normal;
  font-size: 10px;
  text-align: right;
  color: darkgrey;
}

.flex-item-volume {
  width: 60px;
  height: 30px;
  margin-top: 10px;
  line-height: 30px;
  font-weight: normal;
  font-size: 1em;
  text-align: left;
  vertical-align: center;
  alignment: left;
}

.flex-item-input {
  height: 30px;
  margin-top: 10px;
  line-height: 30px;
  font-weight: normal;
  font-size: 1em;
  text-align: left;
  padding-right: 20px;
  margin-bottom: 15px;
  /*background-color: rebeccapurple;*/
  max-width: 90px;
}

.flex-item-wide-input {
  height: 30px;
  margin-top: 10px;
  line-height: 30px;
  font-weight: normal;
  font-size: 1em;
  text-align: left;
  padding-right: 20px;
  margin-bottom: 15px;
  max-width: 600px;
}


.flex-item-button {
  width: 200px;
  font-weight: normal;
  font-size: 1em;
  text-align: right;
  padding-left: 80px;
  padding-bottom: 10px;
  /*background-color: orangered;*/
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}

.flex-item-note {
  width: 60px;
  height: 30px;
  margin-top: 5px;
  line-height: 40px;
  font-weight: normal;
  font-size: 7px;
  text-align: left;
  vertical-align: bottom;
}

.select-item {
  width: 180px;
  height: 30px;
}

.select-item-top {
  width: 140px;
  height: 30px;
  padding: 1px;
}

/* Dropdown Button */
.dropbtn {
  background-color: tomato;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  min-width: 180px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #3e8e41;}


.navigation {
  padding-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: tomato;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}
.navigation-menu {
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .navigation-menu ul {
    display: none;
  }
}

.menu {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  background-color: cornflowerblue;
  font-weight: bolder;
  font-size: 1em;
  margin: 0px;
}

.menuitem {
  padding-top: 2px;
  padding-left: 2px;
  margin: 0px;
}

.input {
  padding: 1px;
  width: 60px;
  height: 15px;
  /*color: yellow;*/
  /*background-color: cornflowerblue;*/
  font-weight: normal;
  font-size: 1em;
  text-align: center;
  border: none;
}

.wide-input {
  padding: 2px;
  width: 1400px;
  height: 30px;
  /*color: yellow;*/
  /*background-color: cornflowerblue;*/
  font-weight: normal;
  font-size: 1em;
  text-align: left;
  border: 1px black;
}

.error {
  padding: 10px;
  width: 600px;
  height: 600px;
  margin-top: 100px;
  line-height: 30px;
  color: tomato;
  font-weight: bolder;
  font-size: 2em;
  text-align: center;
}


.Woolworths {
  background-color: black;
  color: #ebebeb;
  width: 140px;
  height: 30px;
  padding-left: 12px;
  padding-top: 0px;
  padding-bottom: 2px;
  margin-top: 15px;
  line-height: 30px;
  font-weight: bold;
  font-size: 1em;
  text-align: left;
  vertical-align: bottom;
}

.Checkers {
  background-color: teal;
  color: #ebebeb;
  width: 140px;
  height: 30px;
  padding-left: 12px;
  padding-top: 0px;
  padding-bottom: 2px;
  margin-top: 15px;
  line-height: 30px;
  font-weight: bold;
  font-size: 1em;
  text-align: left;
  vertical-align: bottom;
}

.Shoprite {
  background-color: orangered;
  color: #ebebeb;
  width: 140px;
  height: 30px;
  padding-left: 12px;
  padding-top: 0px;
  padding-bottom: 2px;
  margin-top: 15px;
  line-height: 30px;
  font-weight: bold;
  font-size: 1em;
  text-align: left;
  vertical-align: bottom;
}

.PicknPay {
  background-color: mediumblue;
  color: #ebebeb;
  width: 140px;
  height: 30px;
  padding-left: 12px;
  padding-top: 0px;
  padding-bottom: 2px;
  margin-top: 15px;
  line-height: 30px;
  font-weight: bold;
  font-size: 1em;
  text-align: left;
  vertical-align: bottom;
}

.update-button {
  width: 110px;
  height: 30px;
  padding-left: 12px;
  /*padding-top: 2px;*/
  /*padding-bottom: 6px;*/
  margin-top: 10px;
  line-height: 30px;
  font-weight: normal;
  font-size: 1em;
  text-align: center;
  border-width: 0px;
  color: whitesmoke;
  background-color: cornflowerblue;
  border-radius: 22px;
}


